/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveMeta } from "@dataResolvers";
import { Media as PageTemplate } from "@pageTemplates";

export const query = graphql`
query {
  craft {
    entry(section: "media") {
      ... on Craft_media_media_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Hero
        heroLabel
        heroHeading
        # Announcements
        announcements {
          ... on Craft_announcements_announcement_BlockType {
            place
            heading
            description
            announcementLink
            color
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        # Upcoming Events
        boolean2
        heading0
        upcomingEvents {
          ... on Craft_upcomingEvents_event_BlockType {
            date
            heading
            description
            eventLink
          }
        }
        image0 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Full Width
        boolean1
        heading1
        descriptor0
        linkField {
          url
          text
        }
      }
    }
  }
}
`;

const previewQuery = `
query {
  craft {
    entry(section: "media") {
      ... on Craft_media_media_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Hero
        heroLabel
        heroHeading
        # Announcements
        announcements {
          ... on Craft_announcements_announcement_BlockType {
            place
            heading
            description
            announcementLink
            color
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        # Upcoming Events
        boolean2
        heading0
        upcomingEvents {
          ... on Craft_upcomingEvents_event_BlockType {
            date
            heading
            description
            eventLink
          }
        }
        image0 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Full Width
        boolean1
        heading1
        descriptor0
        linkField {
          url
          text
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    title,
    metaTitle,
    metaImage,
    metaDescription,
    slug,
    url,
    // Heading
    heroHeading,
    heroLabel,
    // Announcements
    announcements,
    // Upcoming Events
    boolean2,
    heading0,
    upcomingEvents,
    image0,
    // Full width
    boolean1,
    heading1,
    descriptor0,
    linkField,
  } = craft.entry;

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      label: heroLabel,
      heading: heroHeading,
    },
    announcements: announcements.map((announcement, i , array) => {
      return {
        place: announcement.place,
        heading: announcement.heading,
        description: announcement.description,
        link: announcement.announcementLink,
        color: announcement.color,
        image: resolveImage(announcement.image),
        special: ((array.length - 1) % 5 === 0) && (i + 1 === array.length),
      };
    }),
    events: {
      hide: boolean2,
      heading: heading0,
      image: resolveImage(image0),
      upcoming: upcomingEvents.map(event => {
        return {
          date: event.date,
          heading: event.heading,
          description: event.description,
          link: event.eventLink,
        };
      }),
    },
    fullWidth: {
      hide: boolean1,
      heading: heading1,
      subhead: descriptor0,
      link: linkField,
    },
  }
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
